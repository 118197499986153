import React, { useEffect, useRef, useState } from "react";
import "./TranscriptEditor.scss";
import CloseIcon from "../../../../assets/images/New/close.svg";
import TimerIcon from "../../../../assets/images/New/timerIcon.svg";
import {
  ADD_NEW_LANG,
  LIVE_SUBTITLE_TYPE,
  LIVE_VIDEO_STATUS,
  STREAM_TAB_TYPE,
} from "../../../../Constants/Common/Data";
import ButtonComponent from "../ButtonComponent";
import useStreamStore from "../../../../Store/StreamStore/StreamStore";
import {
  getLangName,
  handleDownload,
  minToSeconds,
  secondsToMinutesAndHours,
} from "../../../../Common/helper/helper";
import { Input, Spinner } from "reactstrap";
import { toast } from "react-hot-toast";
import SubTitleModal from "../SubTitleModal";
import {
  postFetchTextDetails,
  postSetVideoStatus,
} from "../../../../ApiService/ApiCall";
import SpinnerLoader from "../../../Common/Spinner/Spinner";
import {
  CopyIcon,
  DeleteIcon,
  PencilIcon,
  SubtitleDownloadIcon,
  TranscriptRefreshIcon,
} from "../../../Common/Icon";
import ProcessingModal from "../Components/ProcessingModal/ProcessingModal";
import {
  TextDetailsApiPayload,
  TranscriptData,
  VideoStatusApiPayload,
  VideoTextType,
} from "../../../../Common/interface/types";
import UnSavedModal from "../Components/UnSavedModal/UnSavedModal";
import UnSavedIcon from "../../../../assets/images/New/UnSaved.svg";
import { MESSAGE } from "../../../../Constants/Messages";
import CustomDropDown from "../Components/DropDown/DropDown";
import { LIVE_DEEPL_TRANSLATE } from "../../../../Constants/Environment";

const ButtonStyle = {
  width: "85px",
  height: "25px",
  marginTop: "5px",
  marginBottom: "5px",
  background: "#151515",
  zIndex: 1,
};

const CustomTextarea = (props: any) => {
  const {
    onChange,
    index,
    data,
    disableStatus,
    isActive,
    // videoStatus,
    handleClick,
  } = props;
  const MIN_TEXTAREA_HEIGHT = 50;
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dependencyCheck = data[index]?.text;

  React.useLayoutEffect(() => {
    if (textareaRef.current) {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        MIN_TEXTAREA_HEIGHT
      )}px`;
    }
  }, [dependencyCheck]);

  return (
    <>
      {disableStatus ? (
        <div
          onClick={handleClick}
          style={{
            minHeight: MIN_TEXTAREA_HEIGHT,
            resize: "none",
            overflow: "hidden",
            background: "#1e1e1e",
            color: "white",
          }}
          className={`subtitle-content ${
            // videoStatus === LIVE_VIDEO_STATUS?.PAUSE ||
            // videoStatus === LIVE_VIDEO_STATUS?.LIVE_ENDED
            //   ?
            isActive ? "active-text cursor-pointer" : "cursor-pointer"
            // : ""
          }`}
        >
          {data[index].text}
        </div>
      ) : (
        <textarea
          onChange={(e) => onChange(e, index)}
          disabled={disableStatus}
          ref={textareaRef}
          style={{
            minHeight: MIN_TEXTAREA_HEIGHT,
            resize: "none",
            overflow: "hidden",
            background: "#1e1e1e",
            color: "white",
          }}
          className={`subtitle-content ${
            // videoStatus === LIVE_VIDEO_STATUS?.PAUSE ||
            // videoStatus === LIVE_VIDEO_STATUS?.LIVE_ENDED
            //   ?
            isActive ? "active-text cursor-pointer" : "cursor-pointer"
            // : ""
          }`}
          value={data[index].text}
        />
      )}
    </>
  );
};

const TranscriptEditor = (props: any) => {
  const maxMinutes = 180; //180 seconds means 3 minuits
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [languageModalStatus, setLanguageModalStatus] = useState(false);
  const [processingModalStatus, setProcessingModalStatus] = useState(false);
  const [unSavedModalStatus, setUnSavedModalStatus] = useState(false);
  const [isInitial, setIsInitial] = useState(true);
  const [processingModalLang, setProcessingModalLang] = useState<any>(null);
  const [isEdit, setIsEdit] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState<any>(null);
  const [defaultLanguageId, setDefaultLanguageId] = useState<any>(null);
  const [isDefaultSelected, setIsDefaultSelected] = useState<boolean>(false);
  const [sequencingStatus, setSequencingStatus] = useState<boolean>(true);
  const [selectedLangIndex, setSelectedLangIndex] = useState(0); // Default lang
  const [languageList, setLanguageList] = useState<any[]>([]);
  const { toggleComponent } = props;
  const {
    selectedVideoInfo,
    selectedVideoSubtitle,
    setSelectedVideoSubtitle,
    liveVideoPlayerTime,
    selectedVideoStatus,
    videoLoadStatus,
    activeStreamItems,
    setVideoLoadStatus,
    playerReference,
    setIsProgressBarClick,
  } = useStreamStore((state: any) => ({
    selectedVideoInfo: state.selectedVideoInfo,
    selectedVideoSubtitle: state.selectedVideoSubtitle,
    setSelectedVideoSubtitle: state.setSelectedVideoSubtitle,
    liveVideoPlayerTime: state.liveVideoPlayerTime,
    selectedVideoStatus: state.selectedVideoStatus,
    videoLoadStatus: state.videoLoadStatus,
    activeStreamItems: state.activeStreamItems,
    setVideoLoadStatus: state.setVideoLoadStatus,
    playerReference: state.playerReference,
    setIsProgressBarClick: state.setIsProgressBarClick,
  }));
  const [data, setData] = useState<Array<TranscriptData>>(
    !selectedVideoSubtitle[0]?.segments
      ? [
          {
            text: "",
            start: 0,
            end: maxMinutes,
          },
        ]
      : selectedVideoSubtitle[0]?.segments
  );

  const getActiveDiv = () => {
    const elementList: any = document.querySelectorAll(".subtitle-content");
    for (const element of elementList) {
      if (element.classList.contains("active-text")) {
        const containerLeft: any = document.getElementById(
          "live-subtitle-container"
        );
        containerLeft.scrollTop =
          element.offsetTop - containerLeft.offsetTop - 10;
        break;
      }
    }
  };

  useEffect(() => {
    if (sequencingStatus) {
      getActiveDiv();
    }
  }, [liveVideoPlayerTime, selectedVideoInfo, sequencingStatus]);

  // Initial set the subtile info to state
  useEffect(() => {
    if (isInitial) {
      const tempSegment = !selectedVideoSubtitle[0]?.segments
        ? [
            {
              text: "",
              start: 0,
              end: maxMinutes,
            },
          ]
        : selectedVideoSubtitle[0]?.segments;
      setData(tempSegment);
      if (selectedVideoSubtitle && selectedVideoSubtitle.length > 0) {
        const temp: any[] = [];
        selectedVideoSubtitle.forEach((_data: any) => {
          temp.push({
            value: _data?._id,
            label: getLangName(_data?.language[0]),
          });
        });
        setDefaultLanguageId(selectedVideoSubtitle[0]?._id);
        setIsDefaultSelected(true);
        setSelectedLanguage(selectedVideoSubtitle[0]?.language[0]);
        setLanguageList(temp);
      }
      setIsInitial(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoSubtitle, selectedVideoInfo]);

  // Refresh Video Subtitle
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (
      selectedVideoInfo &&
      selectedVideoStatus &&
      (selectedVideoStatus === LIVE_VIDEO_STATUS.LIVE ||
        selectedVideoStatus === LIVE_VIDEO_STATUS.RE_START)
    ) {
      intervalId = setInterval(() => {
        refreshSubtitleData();
      }, 30000); // 30 sec
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoInfo, selectedLangIndex]);

  useEffect(() => {
    if (!activeStreamItems.includes(STREAM_TAB_TYPE.LIVE_STREAM_EDITOR)) {
      setVideoLoadStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStreamItems]);

  // set Data for copy
  const setCopyData = async (data: any[]) => {
    let tempData: any[] = [];
    data.forEach((_item) => {
      tempData.push({ ..._item });
    });
    const text = tempData
      ?.map((segment) => {
        const { text } = segment;
        return `${text}\n`;
      })
      .join("\n");
    return text;
  };

  // download data
  const setDownloadData = async (data: any[]) => {
    let tempData: any[] = [];
    data.forEach((_item) => {
      tempData.push({ ..._item });
    });
    const formattedText = tempData
      ?.map((segment: { end: number; start: number; text: any }) => {
        const startTime = secondsToMinutesAndHours(segment.start);
        const endTime = secondsToMinutesAndHours(segment.end);
        return `IN ${startTime} - OUT ${endTime}\n${segment.text}\n`;
      })
      .join("\n");

    return formattedText;
  };

  // Refresh Video Subtitle
  const refreshSubtitleData = async () => {
    try {
      const out: any = {
        productionResourceId: selectedVideoInfo?._id || selectedVideoInfo?.id,
        type: LIVE_SUBTITLE_TYPE,
      };
      const output: any = await postFetchTextDetails(out);
      if (output !== null || output.length !== 0) {
        setData(output[selectedLangIndex]?.segments);
        setSelectedVideoSubtitle(output);
      }
    } catch (err: any) {
      console.log(err);
    }
  };

  const onChange = (e: any, index: any) => {
    const newState = [...data];
    newState[index].text = e.target.value;
    setData(newState);
  };

  const handleAddNewSubTitle = () => {
    const lastElement = data[data.length - 1];
    setData([
      ...data,
      { text: "", start: lastElement?.end, end: lastElement?.end + maxMinutes },
    ]);
  };

  const handleAdd = (index: any) => {
    const end = data[index]?.end;
    const newData = {
      text: "",
      start: end,
      end: index + 1 >= data?.length ? end + maxMinutes : end,
    };
    const out = [...data];
    out.splice(index + 1, 0, newData);
    setData(out);
  };

  const handleRemove = (index: number) => {
    const out = [...data];
    out.splice(index, 1);
    setData(out);
  };

  const handleMerge = (index: number) => {
    let valOne = data[index].text; //Get Start Index
    let valTwo = data[index + 1].text; //Get End Index
    const endTime = data[index + 1].end; //Get End Time

    let result = valOne.concat(" ", valTwo); //Merge Two Content
    const out = [...data];
    out[index] = { text: result, start: data[index].start, end: endTime };
    out.splice(index + 1, 1);
    setData(out);
  };

  const handleTimeChange = (e: any, index: number, type: string) => {
    let convertToMilSec = minToSeconds(e?.target?.value);
    const newState = [...data];
    type === "start"
      ? (newState[index].start = convertToMilSec)
      : (newState[index].end = convertToMilSec);
    setData(newState);
  };

  const setVideoStatusApi = async (language: string) => {
    const videoStatusApiPayload: VideoStatusApiPayload = {
      productionResourceId: selectedVideoInfo?._id,
      language: language,
      type: 2,
      deeplTranslate: LIVE_DEEPL_TRANSLATE,
    };
    try {
      const res = await postSetVideoStatus(videoStatusApiPayload);
      return res;
    } catch (err: any) {
      toast.error(err?.msg || err.message);
      console.log(err);
    }
  };

  const setLanguage = async (e: any) => {
    const lang: string = e?.value?.toLowerCase();
    setLanguageModalStatus(false);
    setProcessingModalStatus(true);
    const res: any = await setVideoStatusApi(lang);
    if (res && res?.status === true) {
      setSelectedLanguage(lang);
      let resDetails = await getTextDetails(lang);
      if (resDetails) {
        setProcessingModalLang(getLangName(lang));
      }
    } else {
      setProcessingModalStatus(false);
    }
  };

  const getTextDetails = async (language: string) => {
    let res: VideoTextType | any = await getTextDetailsApi(language);
    const index = res && res?.length > 1 ? res?.length - 1 : 0;
    if (res && res[index]) {
      let tempSubtitle = [...selectedVideoSubtitle];
      tempSubtitle.push(res[index]);
      setSelectedVideoSubtitle(tempSubtitle);

      let tempLang = [...languageList];
      tempLang.push({
        value: res[index]?._id,
        label: getLangName(language),
      });

      setSelectedLanguage(language);
      setLanguageList(tempLang);
      setSelectedLangIndex(selectedLangIndex + 1);
      setData(res[index]?.segments);
      setIsDefaultSelected(false);
      return true;
    } else {
      return false;
    }
  };

  const getTextDetailsApi = async (language: string) => {
    try {
      const textDetailsApiPayload: TextDetailsApiPayload = {
        productionResourceId: selectedVideoInfo?._id,
        languages: [language],
        type: 2,
      };

      let res = await postFetchTextDetails(textDetailsApiPayload);
      return res;
    } catch (err: any) {
      toast.error(err?.msg);
      console.log(err);
    }
  };

  // const callTextUpdateApi = async () => {
  //   try {
  //     setLoading(true);
  //     const res: any = await postUpdateSubTittleText({
  //       id: leftSubtitleData?._id,
  //       segments: updatedTextResponse,
  //     });
  //     setIsEdit(false);
  //     toast.success(MESSAGE.SUCCESS.documentUpdated);
  //     // setLoading(false);
  //   } catch (err: any) {
  //     console.log(err);
  //     // setLoading(false);
  //     toast.error(err?.msg || "something went wrong");
  //   }
  // };

  const refreshTranscriptData = async () => {
    if (!selectedVideoInfo?._id)
      return toast.error("Please Select Live Stream");
    if (selectedLanguage === null) {
      return toast.error(MESSAGE.CONTENT.translationNotAvailable);
    }
    const out = {
      productionResourceId: selectedVideoInfo?._id || selectedVideoInfo?.id,
      type: LIVE_SUBTITLE_TYPE,
      languages: [selectedLanguage],
    };
    try {
      setIsLoading(true);
      const output: any = await postFetchTextDetails(out);
      if (output === null || output.length === 0) {
        setIsLoading(false);
        return toast.error("Data Not Available");
      } else {
        setData(output[0]?.segments);
        setIsLoading(false);
      }
    } catch (err: any) {
      setIsLoading(false);
      toast.error(err?.msg || err?.message);
    }
  };

  const handleChangeLanguage = (id: string) => {
    if (id !== ADD_NEW_LANG) {
      selectedVideoSubtitle.forEach((_data: any, index: number) => {
        if (_data?._id === id) {
          setSelectedLanguage(_data?.language[0]);
          setData(_data?.segments);
          setSelectedLangIndex(index);
        }
      });
      if (id === defaultLanguageId) {
        setIsDefaultSelected(true);
      } else {
        setIsDefaultSelected(false);
      }
    }
    if (id === ADD_NEW_LANG) {
      setProcessingModalLang(null);
      setLanguageModalStatus(true);
    }
  };

  // handle Copy
  const handleCopy = async () => {
    if (videoLoadStatus) {
      const testToCopy = await setCopyData(
        selectedVideoSubtitle[selectedLangIndex]?.segments
      );
      navigator.clipboard
        .writeText(testToCopy)
        .then(() => toast.success(MESSAGE.SUCCESS.textCopied))
        .catch((err) => toast.error("Failed to copy"));
    }
  };

  // handle download
  const handleDownloadClick = async () => {
    if (videoLoadStatus) {
      try {
        const downloadData = await setDownloadData(
          selectedVideoSubtitle[selectedLangIndex]?.segments
        );
        handleDownload(downloadData);
        toast.success(
          `Document in ${getLangName(selectedLanguage)} downloaded : TXT`
        );
      } catch (error) {
        toast.error(
          `Download error: Please check the error : ${error} and retry`
        );
      }
    }
  };

  const handleSave = async () => {
    if (!selectedVideoInfo?._id)
      return toast.error("Please Select Live Stream");

    setIsEdit(!isEdit);
    if (unSavedModalStatus) {
      setUnSavedModalStatus(!unSavedModalStatus);
    }
  };

  const handleClick = (startTime: number) => {
    if (playerReference && playerReference != null) {
      setIsProgressBarClick(false);
      playerReference.currentTime(startTime + 1);
    }
  };

  const handleCloseTab = () => {
    if (!isEdit) {
      setUnSavedModalStatus(true);
    } else {
      toggleComponent(STREAM_TAB_TYPE?.TRANSCRIPT_EDITOR);
    }
  };

  return (
    <div className="transcript-container">
      {isLoading ? <SpinnerLoader /> : ""}
      <div className="form-check form-switch form-switch-lg trans-sequence-btn mb-2">
        <Input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="trans-sequence"
          checked={sequencingStatus}
          onChange={() => {
            setSequencingStatus(!sequencingStatus);
          }}
        />
      </div>
      <div className="header-action">
        <div className="heading">Transcript</div>

        <div className="action">
          <div
            className={`action-btn ${videoLoadStatus ? "" : "disable"}`}
            onClick={() => refreshTranscriptData()}
          >
            <TranscriptRefreshIcon />
            Refresh
          </div>
          {isEdit ? (
            <div
              className={`action-btn ${videoLoadStatus ? "" : "disable"}`}
              //  onClick={() => setIsEdit(!isEdit)}
            >
              <PencilIcon />
            </div>
          ) : (
            <div
              className={`action-btn ${videoLoadStatus ? "" : "disable"}`}
              onClick={() => handleSave()}
            >
              Save
            </div>
          )}
          <div
            className={`action-btn ${videoLoadStatus ? "" : "disable"}`}
            onClick={handleCopy}
          >
            <CopyIcon />
          </div>
          <div
            className={`action-btn ${videoLoadStatus ? "" : "disable"}`}
            onClick={handleDownloadClick}
          >
            <SubtitleDownloadIcon />
          </div>
          <CustomDropDown
            placeholder="Languages"
            options={languageList}
            selectedValue={selectedLanguage}
            isDefaultSelected={isDefaultSelected}
            defaultLanguageId={defaultLanguageId}
            videoLoadStatus={videoLoadStatus}
            handleChangeLanguage={handleChangeLanguage}
          />
        </div>
      </div>
      <div className="close-btn" onClick={handleCloseTab}>
        <img src={CloseIcon} alt="close" />
      </div>

      {/* Container */}
      {videoLoadStatus ? (
        <div className="subtitle-main" id="live-subtitle-container">
          <div style={{ marginBottom: "6rem" }}>
            {data?.map((item, index) => {
              return (
                <div key={index}>
                  <div className="subtitle-container">
                    <CustomTextarea
                      onChange={onChange}
                      handleClick={() => handleClick(item.start)}
                      index={index}
                      data={data}
                      videoStatus={selectedVideoInfo?.status}
                      isActive={
                        item?.start <= liveVideoPlayerTime &&
                        item?.end >= liveVideoPlayerTime
                          ? true
                          : false
                      }
                      disableStatus={isEdit}
                    />
                    <div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginBottom: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img src={TimerIcon} alt="Time" />
                          <span>IN</span>
                        </div>
                        <Input
                          disabled
                          type="text"
                          onChange={(e) => handleTimeChange(e, index, "start")}
                          value={secondsToMinutesAndHours(item.start || "0")}
                          step={1}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <img src={TimerIcon} alt="Time" />
                          <span>OUT</span>
                        </div>
                        <Input
                          disabled
                          type="text"
                          step={1}
                          onChange={(e) => handleTimeChange(e, index, "end")}
                          value={secondsToMinutesAndHours(item.end || "0")}
                        />
                      </div>
                    </div>
                    {!isEdit && data.length !== 1 && (
                      <div
                        onClick={() => handleRemove(index)}
                        style={{ cursor: "pointer" }}
                      >
                        <DeleteIcon />
                      </div>
                    )}
                  </div>

                  <div className="text-control-main">
                    <div className="text-control-container">
                      {!isEdit ? (
                        <>
                          <ButtonComponent
                            title="Add Line"
                            onClick={() => handleAdd(index)}
                            style={ButtonStyle}
                          />
                          {data.length - 1 !== index && (
                            <ButtonComponent
                              title="Merge"
                              onClick={() => handleMerge(index)}
                              style={ButtonStyle}
                            />
                          )}
                          <hr className="hr-border" />
                        </>
                      ) : (
                        <div className="gap"></div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
            {!isEdit && (
              <ButtonComponent
                onClick={() => handleAddNewSubTitle()}
                title="+ Add New Subtitle Line"
              />
            )}

            {languageModalStatus && (
              <SubTitleModal
                // alreadyAddedLangList={languageList}
                setSubTitleOpen={setLanguageModalStatus}
                setLanguage={setLanguage}
              />
            )}

            {processingModalStatus && (
              <ProcessingModal
                modalStatus={processingModalStatus}
                setModalStatus={setProcessingModalStatus}
                handleClose={() => {
                  setProcessingModalStatus(false);
                }}
                processingMsg="Subtitle processing..."
                completedMsg="Subtitle changed into"
                data={processingModalLang}
              />
            )}

            {unSavedModalStatus && (
              <UnSavedModal
                show={unSavedModalStatus}
                icon={UnSavedIcon}
                handleClose={() => setUnSavedModalStatus(false)}
                title="Unsaved changes"
                description="Do you want to save or discard changes?"
                actionType="Save Changes"
                handleSubmit={handleSave}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="loading">
          <Spinner color="dark"></Spinner>
          <div>Loading...</div>
        </div>
      )}
    </div>
  );
};

export default TranscriptEditor;
