export const transServiceLanguageList = [
  { value: "AR", label: "Arabic" },
  { value: "AZ", label: "Azerbaijani" },
  { value: "BG", label: "Bulgarian" },
  { value: "BN", label: "Bengali" },
  { value: "CA", label: "Catalan" },
  { value: "ZH", label: "Chinese" },
  { value: "ZT", label: "Chinese (traditional)" },
  { value: "CS", label: "Czech" },
  { value: "DA", label: "Danish" },
  { value: "DE", label: "German" },
  { value: "EL", label: "Greek" },
  { value: "EN", label: "English" },
  { value: "EO", label: "Esperanto" },
  { value: "ES", label: "Spanish" },
  { value: "ET", label: "Estonian" },
  { value: "FA", label: "Persian" },
  { value: "FI", label: "Finnish" },
  { value: "FR", label: "French" },
  { value: "GA", label: "Irish" },
  { value: "HE", label: "Hebrew" },
  { value: "HI", label: "Hindi" },
  { value: "HU", label: "Hungarian" },
  { value: "ID", label: "Indonesian" },
  { value: "IT", label: "Italian" },
  { value: "JA", label: "Japanese" },
  { value: "KO", label: "Korean" },
  { value: "LT", label: "Lithuanian" },
  { value: "LV", label: "Latvian" },
  { value: "MS", label: "Malay" },
  { value: "NB", label: "Norwegian" },
  { value: "NL", label: "Dutch" },
  { value: "PL", label: "Polish" },
  { value: "PT", label: "Portuguese" },
  { value: "RO", label: "Romanian" },
  { value: "RU", label: "Russian" },
  { value: "SK", label: "Slovak" },
  { value: "SL", label: "Slovenian" },
  { value: "SQ", label: "Albanian" },
  { value: "SV", label: "Swedish" },
  { value: "TH", label: "Thai" },
  { value: "TL", label: "Tagalog" },
  { value: "TR", label: "Turkish" },
  { value: "UK", label: "Ukranian" },
  { value: "UR", label: "Urdu" },
];

export const deeplLanguageList = [
  { value: "BG", label: "Bulgarian" },
  { value: "CS", label: "Czech" },
  { value: "DA", label: "Danish" },
  { value: "DE", label: "German" },
  { value: "EL", label: "Greek" },
  { value: "EN", label: "English" },
  { value: "ES", label: "Spanish" },
  { value: "ET", label: "Estonian" },
  { value: "FI", label: "Finnish" },
  { value: "FR", label: "French" },
  { value: "HU", label: "Hungarian" },
  { value: "ID", label: "Indonesian" },
  { value: "IT", label: "Italian" },
  { value: "JA", label: "Japanese" },
  { value: "KO", label: "Korean" },
  { value: "LT", label: "Lithuanian" },
  { value: "LV", label: "Latvian" },
  { value: "NB", label: "Norwegian" },
  { value: "NL", label: "Dutch" },
  { value: "PL", label: "Polish" },
  { value: "PT", label: "Portuguese" },
  { value: "RO", label: "Romanian" },
  { value: "RU", label: "Russian" },
  { value: "SK", label: "Slovak" },
  { value: "SL", label: "Slovenian" },
  { value: "SV", label: "Swedish" },
  { value: "TR", label: "Turkish" },
  { value: "UK", label: "Ukrainian" },
  { value: "ZH", label: "Chinese" },
];

export const AddOnLanguageList = [{ value: "ZH-CN", label: "Chinese" }];
