import React, { useEffect, useState } from "react";
import { Input, Form, FormGroup, Label } from "reactstrap";
import ModalLayout from "../../Common/ModalLayout";
import ButtonComponent from "./ButtonComponent";
import RepeatIcon from "../../../assets/images/New/repeat.svg";
import RepeatIconGreen from "../../../assets/images/New/RepeatIconGreen.svg";
import Select from "react-select";
import useStreamStore from "../../../Store/StreamStore/StreamStore";

import { toast } from "react-hot-toast";
import {
  AutoDetectStream,
  createLiveStream,
  getCheckLiveUrlExist,
  updateLiveStream,
} from "../../../ApiService/ApiCall";
import SpinnerLoader from "../../Common/Spinner/Spinner";
import {
  AUTO_DETECT_FORM_TYPE,
  CONFIRMATION_MODAL_TYPE,
} from "../../../Constants/Common/Data";
import StreamSaveProcess from "./Components/StreamModal/StreamSaveProcess";
import ProcessingModal from "./Components/ProcessingModal/ProcessingModal";
import ConfirmationModal from "./ConfirmationModel/ConfirmationModel";
import DeletePopupIcon from "../../../assets/images/New/DeletePopupIcon.svg";
import { LangListForWhisper } from "../../../Constants/Common/LangListForWhisper";
import { MESSAGE } from "../../../Constants/Messages";
import { LiveVideoAddFormDataDefaultValue } from "../../../Common/interface/defaultValue";
import usePermissionStore from "../../../Store/PermissionStore/PermissionStore";

const ButtonStyle = {
  width: "150px",
  height: "38px",
  border: "1px solid #6D6D6D",
  background: "#212529",
  borderRadius: "0.25rem",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  gap: 10,
};

const BorderRadius = {
  borderRadius: "8px",
};

const AutoDetectForm = (props: any) => {
  const {
    openDetectForm,
    setOpenDetectForm,
    isEdit,
    setFormData,
    formData,
    currentStream,
    startStream,
    setInfoShow,
    setVideoType,
    setAutoDetectData,
    autoDetectData,
    setIsProcess,
  } = props;
  const [isSaveProcess, setSaveIsProcess] = useState<boolean>(false); //set Open Progress modal for save
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAutoDetect, setIsAutoDetect] = useState<boolean>(false);
  const [isAutoDetectError, setIsAutoDetectError] = useState<boolean>(false);
  const [isAutoDetectErrorMsg, setIsAutoDetectErrorMsg] = useState(
    MESSAGE.CONTENT.internalServer
  );
  const [autoDetectLang, setAutoDetectLang] = useState("");
  const [autoDetectCancelToken, setAutoDetectCancelToken] = useState<any>(null);
  const [languageArray, setLanguageArray] = useState(LangListForWhisper);
  const [videoChannelArray, setVideoChannelArray] = useState([
    { value: "ch1", label: "Video Channel 1" },
    { value: "ch2", label: "Video Channel 2" },
    { value: "ch3", label: "Video Channel 3" },
    { value: "ch4", label: "Video Channel 4" },
  ]);
  const [audioChannelArray, setAudioChannelArray] = useState([
    { value: "ch1", label: "Audio Channel 1" },
    { value: "ch2", label: "Audio Channel 2" },
    { value: "ch3", label: "Audio Channel 3" },
    { value: "ch4", label: "Audio Channel 4" },
  ]);

  const [languageValue, setLanguageValue] = useState({});
  const [videoChannelValue, setVideoChannelValue] = useState({});
  const [audioChannelValue, setAudioChannelValue] = useState({});

  const {
    totalCount,
    setStreamList,
    streamVideoList,
    selectedVideoInfo,
    setSelectedVideoInfo,
    setInfoModalMessage,
    setInfoModalStatus,
    setTotalCount,
  } = useStreamStore((state: any) => ({
    totalCount: state.totalCount,
    setStreamList: state.setStreamList,
    streamVideoList: state.streamVideoList,
    selectedVideoInfo: state.selectedVideoInfo,
    setSelectedVideoInfo: state.setSelectedVideoInfo,
    setInfoModalMessage: state.setInfoModalMessage,
    setInfoModalStatus: state.setInfoModalStatus,
    setTotalCount: state.setTotalCount,
  }));

  const { liveFieldPermission } = usePermissionStore();

  useEffect(() => {
    if (isEdit !== null) {
      setAudioChannelArray(formData?.channelDetails?.audioChannel);
      setVideoChannelArray(formData?.channelDetails?.videoChannel);
      let languageValue = languageArray?.filter(
        (lang) => lang.value === formData?.language
      );
      let videoChannelVal = formData?.channelDetails?.videoChannel?.filter(
        (vid: any) => vid.value === formData?.videoChannel
      );
      let audioChannelVal = formData?.channelDetails?.audioChannel?.filter(
        (audio: any) => audio.value === formData?.audioChannel
      );
      setLanguageValue(languageValue && languageValue[0]);
      setVideoChannelValue(videoChannelVal && videoChannelVal[0]);
      setAudioChannelValue(audioChannelVal && audioChannelVal[0]);
    }
  }, [isEdit, formData, languageArray]);

  useEffect(() => {
    let languageValue = languageArray?.filter(
      (lang) => lang.value === formData?.language
    );
    let videoChannelVal = videoChannelArray?.filter(
      (vid) => vid.value === formData?.videoChannel
    );
    let audioChannelVal = audioChannelArray?.filter(
      (audio) => audio.value === formData?.audioChannel
    );
    setLanguageValue(languageValue && languageValue[0]);
    setVideoChannelValue(videoChannelVal && videoChannelVal[0]);
    setAudioChannelValue(audioChannelVal && audioChannelVal[0]);

    return () => {
      setLanguageValue({});
      setVideoChannelValue({});
      setAudioChannelValue({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [languageArray, videoChannelArray, audioChannelArray]);

  const handleChange = (e: any) => {
    if (!e) return;
    const { value, name } = e?.target;
    if (e?.target) {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSelect = (e: any, name: string) => {
    if (!e) return;
    setFormData({
      ...formData,
      [name]: e?.value,
    });
    if (name === "videoChannel") {
      let videoChannelVal = videoChannelArray?.filter(
        (vid) => vid.value === e?.value
      );
      setVideoChannelValue(videoChannelVal[0]);
    }
    if (name === "audioChannel") {
      let audioChannelVal = audioChannelArray?.filter(
        (audio) => audio.value === e?.value
      );
      setAudioChannelValue(audioChannelVal[0]);
    }
    if (name === "language") {
      let languageValue = languageArray?.filter(
        (lang) => lang.value === e?.value
      );
      setLanguageValue(languageValue[0]);
    }
  };

  const addNewStream = async (type: string) => {
    const out = {
      title: formData?.title,
      audioChannel: formData?.audioChannel,
      videoChannel: formData?.videoChannel,
      videoUrl: formData?.liveVideoUrl,
      description: formData?.description,
      language: formData?.language,
      channelDetails: {
        audioChannel: autoDetectData?.audioChannels || [],
        videoChannel: autoDetectData?.videoChannels || [],
        language: autoDetectData?.language || [],
      },
    };
    try {
      // setIsLoading(true);
      if (type === AUTO_DETECT_FORM_TYPE.START) {
        setIsProcess(true); //Showing the Progress modal for start
      }
      if (type === AUTO_DETECT_FORM_TYPE.SAVE) {
        setSaveIsProcess(true); //Showing the Progress modal for save
      }
      setOpenDetectForm(false);
      const res: any = await createLiveStream(out);
      const prevList = streamVideoList;
      const newList = {
        ...prevList,
        result: [res?.data, ...prevList.result],
        total: prevList.total + 1,
      };
      setTotalCount(totalCount + 1);
      setStreamList(newList);
      if (type === AUTO_DETECT_FORM_TYPE.START) {
        setVideoType(res?.data?.type);
        setIsProcess(false);
      }
      if (type === AUTO_DETECT_FORM_TYPE.SAVE) {
        setSaveIsProcess(false);
        setInfoShow(true); //showing information modal
        toast.success(MESSAGE?.SUCCESS?.saveStream);
      }
      setLanguageValue({});
      setVideoChannelValue({});
      setAudioChannelValue({});
      return { res, newList };
    } catch (error: any) {
      setIsLoading(false);
      setIsProcess(false);
      setSaveIsProcess(false);
      setOpenDetectForm(false);
      setInfoModalMessage(error?.msg || error?.message);
      setInfoModalStatus(true);
    }
  };

  const handleValidation = () => {
    let error = true;
    if (formData?.title.trim() === "") {
      error = false;
      toast.error(MESSAGE?.ERROR?.videoTitle);
    }

    if (formData?.title.trim().length >= 150) {
      error = false;
      toast.error("A maximum of 150 characters is allowed for the video title");
    }

    if (formData?.liveVideoUrl === "") {
      error = false;
      toast.error(MESSAGE?.ERROR?.videoUrl);
    }
    return error;
  };

  // Handle Start
  const handleStart = async () => {
    if (!handleValidation()) return;
    setIsLoading(true);
    const res = await checkUrl(formData?.liveVideoUrl);
    setIsLoading(false);
    if (!res) {
      const res = await addNewStream(AUTO_DETECT_FORM_TYPE.START);
      if (res) {
        startStream(res);
      }
    } else {
      toast.error(MESSAGE.ERROR.urlExist);
    }
  };

  // handle Save
  const handleSave = async () => {
    if (!handleValidation()) return;
    setIsLoading(true);
    const res: any = await checkUrl(formData?.liveVideoUrl);
    setIsLoading(false);
    if (!res) {
      await addNewStream(AUTO_DETECT_FORM_TYPE.SAVE);
    } else {
      toast.error(MESSAGE.ERROR.urlExist);
    }
  };

  const checkUrl = async (url: string) => {
    try {
      setIsLoading(true);
      const payload = {
        videoUrl: url,
      };
      const result: any = await getCheckLiveUrlExist(payload);
      setIsLoading(false);
      return result?.status;
    } catch (err: any) {
      console.log(err);
      setIsLoading(false);
      toast.error(err?.msg || err?.message);
    }
  };

  const handleEdit = async () => {
    if (isEdit !== null || isEdit) {
      if (!handleValidation()) return;
      const out = {
        ...formData,
        id: formData?._id,
      };
      const updatedStream = streamVideoList?.result?.map(
        (stream: any, index: any) => (index === currentStream ? out : stream)
      );
      try {
        if (selectedVideoInfo?._id === formData?._id) {
          setSelectedVideoInfo(out);
        }
        setIsLoading(true);
        setOpenDetectForm(false);
        await updateLiveStream(out);
        setIsLoading(false);
        setStreamList({ result: updatedStream });
        setInfoModalMessage("Live Stream Updated");
        setInfoModalStatus(true);
      } catch (err: any) {
        setIsLoading(false);
        setOpenDetectForm(false);
        toast.error(err?.msg || err?.message);
      }
    }
  };

  const handleAutoDetect = async () => {
    setAutoDetectLang("");
    if (!formData?.liveVideoUrl) {
      //Video URL Required
      toast.error(MESSAGE?.ERROR?.videoUrl);
      return;
    }
    setIsAutoDetect(true);
    const abortController = new AbortController();
    setAutoDetectCancelToken(abortController);
    try {
      const payload = {
        videoUrl: formData?.liveVideoUrl,
      };
      const out: any = await AutoDetectStream(payload, abortController.signal);
      if (out) {
        setAutoDetectData(out);
        setAutoDetectLang(out?.language || "Unknown");
        setVideoChannelArray(
          out?.videoChannels?.length === 0
            ? videoChannelArray
            : out?.videoChannels
        );
        setAudioChannelArray(
          out?.audioChannels?.length === 0
            ? audioChannelArray
            : out?.audioChannels
        );
        if (out?.language && out?.language !== "Unknown") {
          setLanguageArray(out?.language);
        }
        // Data Load In form auto detect
        setFormData({
          ...formData,
          description: out?.description,
          title: out?.title,
        });
      }
      if (out?.statusCode && out?.statusCode === 500) {
        setIsAutoDetect(false);
        setIsAutoDetectErrorMsg(MESSAGE.CONTENT.internalServer);
        setIsAutoDetectError(true);
      }
    } catch (err: any) {
      setIsAutoDetect(false);
      if (err?.message?.code && err?.message?.code === "ERR_CANCELED") {
        setIsAutoDetectErrorMsg(MESSAGE.CONTENT.internalServer);
      } else {
        setIsAutoDetectErrorMsg(
          err?.msg || err?.message || MESSAGE.CONTENT.internalServer
        );
      }
      setIsAutoDetectError(true);
      console.log(err);
    }
  };

  const handleProcessingModelClose = () => {
    setIsAutoDetect(false);
    autoDetectCancelToken?.abort();
  };

  const handleCloseConfirmationModal = () => {};

  const handleCloseModal = () => {
    setOpenDetectForm(false);
    setLanguageValue({});
    setVideoChannelValue({});
    setAudioChannelValue({});
    setFormData(LiveVideoAddFormDataDefaultValue);
  };

  const checkUpdatePermission = () => {
    if (isEdit !== null) {
      if (liveFieldPermission.UPDATE) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      {isLoading ? <SpinnerLoader /> : ""}
      <ModalLayout
        isShowing={openDetectForm}
        hide={handleCloseModal}
        style={{ background: "rgba(26, 26, 26, 1)" }}
      >
        <Form className="auto-detect-form">
          <div className="form-group-container">
            <FormGroup className="form-group">
              <Label>Add new SRT Stream</Label>
              <Input
                name="liveVideoUrl"
                placeholder="SRT"
                onChange={(e) => handleChange(e)}
                value={formData?.liveVideoUrl}
                disabled={isEdit !== null ? true : false}
                className={isEdit !== null ? "disable" : ""}
              />
            </FormGroup>
            {liveFieldPermission.AUTO_DETECT && (
              <div>
                <FormGroup className="form-group">
                  <div
                    style={{
                      ...ButtonStyle,
                      color: autoDetectData ? "#008000" : "",
                      borderColor: autoDetectData ? "#008000" : "",
                    }}
                    onClick={() => handleAutoDetect()}
                  >
                    <span>Auto-Detect</span>
                    <img
                      alt="icon"
                      src={autoDetectData ? RepeatIconGreen : RepeatIcon}
                    />
                  </div>
                </FormGroup>
              </div>
            )}
          </div>

          <div className="form-group-container">
            <FormGroup className="form-group">
              <Label>Add Stream title</Label>
              <Input
                name="title"
                placeholder="Bundestag Live stream"
                onChange={(e) => handleChange(e)}
                value={formData?.title}
                disabled={checkUpdatePermission()}
                className={checkUpdatePermission() ? "disable" : ""}
              />
            </FormGroup>
            <FormGroup className="form-group">
              <Label>Select a language</Label>
              <Select
                onChange={(e: any) => handleSelect(e, "language")}
                options={languageArray}
                name="language"
                placeholder="Select from the list or search..."
                value={languageValue}
                isDisabled={checkUpdatePermission()}
              />
            </FormGroup>
          </div>

          <div className="form-group-container">
            <FormGroup className="form-group">
              <Label>Video Channel</Label>
              <Select
                onChange={(e: any) => handleSelect(e, "videoChannel")}
                options={videoChannelArray}
                name="videoChannel"
                placeholder="Video Channel"
                value={videoChannelValue}
                isDisabled={checkUpdatePermission()}
              />
            </FormGroup>
            <FormGroup className="form-group">
              <Label>Audio Channel</Label>

              <Select
                onChange={(e: any) => handleSelect(e, "audioChannel")}
                options={audioChannelArray}
                placeholder="Audio Channel"
                name="audioChannel"
                value={audioChannelValue}
                isDisabled={checkUpdatePermission()}
              />
            </FormGroup>
          </div>

          <FormGroup>
            <Label>Add a stream description</Label>
            <Input
              name="description"
              rows={3}
              type="textarea"
              onChange={(e) => handleChange(e)}
              value={formData?.description}
              disabled={checkUpdatePermission()}
              className={checkUpdatePermission() ? "disable" : ""}
            />
          </FormGroup>
        </Form>
        <div className="button">
          {isEdit !== null || isEdit ? (
            <>
              {liveFieldPermission.UPDATE && (
                <ButtonComponent
                  title="Edit"
                  style={BorderRadius}
                  onClick={handleEdit}
                />
              )}
            </>
          ) : (
            <>
              {liveFieldPermission.CREATE && (
                <>
                  {liveFieldPermission.START_STREAM && (
                    <ButtonComponent
                      title="START"
                      style={BorderRadius}
                      className="start-btn"
                      onClick={handleStart}
                    />
                  )}
                  <ButtonComponent
                    title="SAVE"
                    className="save-btn"
                    style={BorderRadius}
                    onClick={handleSave}
                  />
                </>
              )}
            </>
          )}
        </div>
      </ModalLayout>
      {isSaveProcess && (
        <StreamSaveProcess
          setIsSaveProcess={setSaveIsProcess}
          isSaveProcess={isSaveProcess}
        />
      )}
      {isAutoDetect && (
        <ProcessingModal
          data={autoDetectLang}
          setModalStatus={setIsAutoDetect}
          modalStatus={isAutoDetect}
          handleClose={handleProcessingModelClose}
          processingMsg="Auto-Detection is processing..."
          completedMsg="Auto-Detection completed"
        />
      )}

      {isAutoDetectError && (
        <ConfirmationModal
          show={isAutoDetectError}
          handleClose={handleCloseConfirmationModal}
          title="Auto-Detection error"
          icon={DeletePopupIcon}
          description={isAutoDetectErrorMsg}
          actionType="Okay"
          modalType={CONFIRMATION_MODAL_TYPE.INFO}
          handleSubmit={() => setIsAutoDetectError(false)}
        />
      )}
    </>
  );
};

export default AutoDetectForm;
