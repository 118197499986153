import { APIClient } from "./ApiService";

import * as url from "./UrlHelper";

const api = new APIClient();

// Get Keyword Data
export const postVideoFileUpload = (data: any) =>
  api.create(url.POST_VIDEO_FILE_UPLOAD, data);
export const getResources = (data: any) => api.create(url.GET_RESOURCES, data);
export const getLangList = (data: any) => api.create(url.GET_LANG_LIST, data);
export const postSetVideoStatus = (data: any) =>
  api.create(url.POST_VIDEO_STATUS, data);
export const postFetchTextDetails = (data: any) =>
  api.create(url.POST_FETCH_TEXT_DETAILS, data);
export const getQueueResources = (data: any) =>
  api.create(url.GET_QUEUE_RESOURCES, data);
export const getCurrentUser = (data: any) =>
  api.get(url.GET_CURRENT_USER, data);
export const postCreateUploadUrl = (data: any) =>
  api.create(url.POST_CREATE_UPLOAD_URL, data);
export const postDeleteResource = (data: any) =>
  api.create(url.POST_DELETE_RESOURCE, data);
export const postDeleteUrl = (data: any) =>
  api.create(url.POST_DELETE_URL, data);
export const postDeleteSubtitle = (data: any) =>
  api.create(url.POST_DELETE_SUBTITLE, data);
export const getValidateUrl = (data: any) =>
  api.get(url.GET_VALIDATE_URL, data);
export const postAddSubtitle = (data: any) =>
  api.create(url.POST_ADD_SUBTITLE, data);
export const postDeleteTextResource = (data: any) =>
  api.create(url.POST_DELETE_TEXT_RESOURCE, data);
export const postReProcessVideo = (data: any) =>
  api.create(url.POST_REPROCESS_VIDEO, data);
export const postCompressFile = (data: any) =>
  api.create(url.POST_COMPRESS_FILE, data);
export const postReprocessAudio = (data: any) =>
  api.create(url.POST_REPROCESS_AUDIO, data);
export const postUpdateUserDefaultSetting = (data: any) =>
  api.create(url.POST_USER_DEFAULT_SETTING, data);
export const postRenameFile = (data: any) =>
  api.create(url.POST_FILE_RENAME, data);

// Subtitle
export const postReviewText = (data: any) =>
  api.create(url.POST_REVIEW_TEXT, data);

//User
export const createUser = (data: any) => api.create(url.POST_CREATE_USER, data);
export const getUser = (data: any) => api.create(url.GET_USER_LISTING, data);
export const updateUser = (data: any) => api.create(url.UPDATE_USER, data);
export const deleteUser = (data: any) => api.create(url.DELETE_USER, data);

// Url
export const getUrlList = (data: any) => api.create(url.POST_URL_LIST, data);
export const postUrlExpire = (data: any) =>
  api.create(url.POST_URL_EXPIRE, data);

// Share
export const getUserList = (data: any) =>
  api.get(url.GET_USER_LIST_FOR_SHARE, data);
export const postShareResource = (data: any) =>
  api.create(url.POST_SHARE_RESOURCE, data);

// SubtitleEdit
export const postUpdateSubTittleText = (data: any) =>
  api.create(url.POST_UPDATE_TEXT, data);

// VideoDetail Modal
export const getVideoDetail = (params: any) =>
  api.get(url.GET_VIDEO_DETAIL, params);
export const getAudioWaveFormData = (params: any) =>
  api.get(url.GET_AUDIO_WAVE_FORM_DATA, params);

export const getTimeSpendData = (params: any) =>
  api.get(url.GET_TIME_SPEND, params);

//Live Stream Modal
export const createLiveStream = (data: any) =>
  api.create(url.CREATE_LIVE_STREAM, data);
export const getLiveStreamList = (data: any) =>
  api.create(url.GET_LIVE_STREAM_LISTING, data);
export const updateLiveStream = (data: any) =>
  api.create(url.UPDATE_LIVE_STREAM, data);
export const AutoDetectStream = (
  params: any,
  signal?: AbortSignal | undefined
) => api.get(url.AUTO_DETECT_LIVE_STREAM, params, signal);
export const createShortSummary = (data: any) =>
  api.create(url.POST_ADD_SUBTITLE, data);
export const getShortSummary = (data: any) =>
  api.create(url.POST_FETCH_TEXT_DETAILS, data);
export const deleteLiveStream = (data: any) =>
  api.create(url.DELETE_LIVE_STREAM, data);
export const getCheckVideoStatus = (params: any) =>
  api.get(url.GET_CHECK_VIDEO_STATUS, params);
export const postStartVideoStatus = (data: any) =>
  api.create(url.POST_START_STREAM, data);
export const getCheckLiveExist = (params: any) =>
  api.get(url.GET_CHECK_LIVE_STREAM_EXIST, params);
export const getCheckLiveUrlExist = (params: any) =>
  api.get(url.GET_CHECK_LIVE_URL_EXIST, params);
export const getServerTime = (params: any) =>
  api.get(url.GET_SERVER_TIME, params);
export const postFtpFileUpload = (
  params: any,
  signal?: AbortSignal | undefined
) => api.create(url.POST_FTP_FILE_UPLOAD, params, signal);
