import "./ManualTransSection.scss";
import { useEffect, useState } from "react";
import Select from "react-select";
import DeleteIcon from "../../../../assets/images/New/Delete.svg";
import AddIcon from "../../../../assets/images/New/Add.svg";
import RightIcon from "../../../../assets/images/New/Right.svg";
import {
  ManualTransSectionProps,
  textType,
} from "../../../../Common/interface/types";
import {
  getFlagByLang,
  getLangListByVideoType,
  getLangName,
  minToSeconds,
  secondsToMinutesAndHours,
} from "../../../../Common/helper/helper";
import useTranscriptionStore from "../../../../Store/TranscriptionStore/TranscriptionStore";
import { postAddSubtitle } from "../../../../ApiService/ApiCall";
import DeleteModal from "../../Common/DeleteModal";
import { VideoSegmentsTypeDefaultValue } from "../../../../Common/interface/defaultValue";
import Flatpickr from "react-flatpickr";
import useTabStore from "../../../../Store/TabStore/TabStore";
import { handleDivResize } from "../../../../Common/helper/util";
import { TAB_TYPE, VIDEO_TYPE } from "../../../../Constants/Common/Data";
import { Tooltip } from "reactstrap";
import { MESSAGE } from "../../../../Constants/Messages";
import Toast from "../../../../Common/helper/toasterService";

const ManualTransSection = (props: ManualTransSectionProps) => {
  const { onClose, onAddSubtitle } = props;
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [selectedLang, setSelectedLang] = useState<any>([]);
  const [addLangStatus, setAddLangStatus] = useState(true);
  const [selectedLangValue, setSelectedLangValue] = useState("");
  const [TextSegments, setTextSegments] = useState<textType[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSubtitle, setSelectedSubtitle] = useState<textType>(
    VideoSegmentsTypeDefaultValue
  );
  const [divSize, setDivSize] = useState(false);

  const { selectedVideoInfo, setLoading, setSubtitleData, subtitleData } =
    useTranscriptionStore((state: any) => ({
      selectedVideoInfo: state.selectedVideoInfo,
      setLoading: state.setLoading,
      setSubtitleData: state.setSubtitleData,
      subtitleData: state.subtitleData,
    }));

  const { windowSmallSize, activeItems } = useTabStore((state: any) => ({
    windowSmallSize: state.windowSmallSize,
    activeItems: state.activeItems,
    inActiveItem: state.inActiveItem,
  }));

  const handleAddLang = async (selectedValue: any) => {
    setSelectedLang(selectedValue);
    setSelectedLangValue(selectedValue.label);
    setAddLangStatus(false);
  };

  useEffect(() => {
    const containerDiv = document.getElementById("manual-trans-section");
    const handleResize = () => {
      if (containerDiv && containerDiv?.offsetWidth < 765) {
        setDivSize(true);
      } else {
        setDivSize(false);
      }
    };
    containerDiv?.addEventListener("resize", handleResize);

    var resizeObserver = new ResizeObserver(handleResize);
    containerDiv && resizeObserver.observe(containerDiv);

    return () => {
      containerDiv?.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const firstSegment = {
      end: 3,
      id: 0,
      start: 0,
      text: "",
    };
    setTextSegments((prevSegment) => [...prevSegment, { ...firstSegment }]);
  }, []);

  const handleResizeDiv = () => {
    const containerDiv = document.getElementById("manual-trans-section");
    handleDivResize(containerDiv, setDivSize);
  };

  const handleTextareaChange = (index: number, newText: string) => {
    const updatedArray = [...TextSegments];
    updatedArray[index].text = newText;
    setTextSegments(() => {
      return updatedArray;
    });
  };

  const handleTimeChange = (
    index: number,
    field: "start" | "end",
    value: any
  ) => {
    const updatedArray = [...TextSegments];
    const updatedValue = minToSeconds(value);
    if (field === "start") {
      updatedArray[index][field] = updatedValue;
      if (updatedValue + 3 <= selectedVideoInfo?.duration) {
        updatedArray[index]["end"] = updatedValue + 3;
      }
    } else {
      updatedArray[index]["end"] = updatedValue;
    }

    setTextSegments(() => {
      return updatedArray;
    });
  };

  const handleDeleteSubtitleModal = (subtitleInfo: textType) => {
    setSelectedSubtitle(subtitleInfo);
    setShowDeleteModal(true);
  };

  const handleDeleteSubtitle = () => {
    if (!selectedSubtitle) return;

    if (TextSegments.length === 1) {
      Toast.showCustom(MESSAGE.WARNING.lastSubtitleDelete);
      setShowDeleteModal(false);
    } else {
      const updatedArray = TextSegments.filter(
        (item) => item.id !== selectedSubtitle.id
      );
      setTextSegments(updatedArray);
      setShowDeleteModal(false);
    }
  };

  const handleAddLine = () => {
    const lastObject =
      TextSegments.length > 0 ? TextSegments[TextSegments.length - 1] : null;
    const start = lastObject ? lastObject.end : 0;
    if (
      start <= selectedVideoInfo?.duration &&
      start + 3 <= selectedVideoInfo?.duration
    ) {
      const segment = {
        end: start + 3,
        id: lastObject ? lastObject.id + 1 : 0,
        start: start,
        text: "",
      };
      setTextSegments((prevSegment) => [...prevSegment, { ...segment }]);
    }
  };

  const checkSegment = () => {
    let isValid = true;

    TextSegments.forEach((_currentText) => {
      if (_currentText.text.trim() === "") {
        isValid = false;
      }
    });

    return isValid;
  };

  const submit = async () => {
    if (checkSegment()) {
      try {
        setLoading(true);
        const subtitleInfo = {
          productionResourceId: selectedVideoInfo?._id,
          segments: TextSegments,
          language: selectedLang?.value.toLowerCase(),
        };
        await postAddSubtitle(subtitleInfo);
        await onAddSubtitle(selectedLang?.value.toLowerCase());
        setSubtitleData([...subtitleData, subtitleInfo]);
        Toast.showSuccess(MESSAGE.SUCCESS.subtitleAdded);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
        Toast.showError(err?.msg || "Something went wrong");
      }
    } else {
      Toast.showError(MESSAGE.ERROR.emptyTextBox);
    }
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div
      className={`manual-trans-section ${
        activeItems.includes(TAB_TYPE.WAVE) ? "h-80" : ""
      }`}
      id="manual-trans-section"
      onResize={handleResizeDiv}
    >
      {addLangStatus ? (
        <>
          <div className="select-lang-outer">
            <Select
              className="lang-select"
              onChange={handleAddLang}
              value={selectedLang}
              options={getLangListByVideoType(VIDEO_TYPE.UPLOAD)}
              placeholder="Select Language"
            />
            {addLangStatus && (
              <div
                className="cursor-pointer"
                onClick={() => {
                  setAddLangStatus(false);
                  onClose();
                }}
              >
                close
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="header-action">
            {!addLangStatus && selectedLangValue && (
              <div className="default-lang-outer">
                <div className="lang" id="lang">
                  {getFlagByLang(selectedLangValue) && (
                    <img
                      className="flag-icon"
                      src={`https://unpkg.com/language-icons/icons/${getFlagByLang(
                        selectedLangValue
                      )}.svg`}
                      alt="flag"
                    />
                  )}
                </div>
                <Tooltip
                  placement="bottom"
                  isOpen={tooltipOpen}
                  autohide={true}
                  target={`lang`}
                  toggle={toggleTooltip}
                >
                  {getLangName(selectedLangValue)}
                </Tooltip>
              </div>
            )}
            <div>
              <div
                className={`new-lang cursor-pointer`}
                onClick={handleAddLine}
              >
                <img className="mx-1" src={AddIcon} alt="add" />
                {!windowSmallSize && !divSize ? "Add" : ""}
              </div>
            </div>
            <div>
              <div className={`new-lang cursor-pointer`} onClick={submit}>
                <img className="mx-1" src={RightIcon} alt="submit"></img>
                {!windowSmallSize && !divSize ? "Submit" : ""}
              </div>
            </div>
          </div>
          <div className="manual-text-main-outer">
            {TextSegments &&
              TextSegments.length > 0 &&
              TextSegments?.map((_data: textType, index: any) => {
                return (
                  <div className="manual-subtitle-text-outer" key={index}>
                    <div className="heading">
                      <div className="time">
                        IN
                        <Flatpickr
                          className="custom-flat-picker"
                          value={secondsToMinutesAndHours(_data?.start)}
                          onChange={(e, dateStr) =>
                            handleTimeChange(index, "start", dateStr)
                          }
                          options={{
                            enableSeconds: true,
                            enableTime: true,
                            noCalendar: true,
                            time_24hr: true,
                            hourIncrement: 1,
                            minuteIncrement: 1,
                            dateFormat: "H:i:S",
                            maxTime: secondsToMinutesAndHours(
                              selectedVideoInfo?.duration
                            ),
                            inline: true,
                          }}
                        />
                      </div>
                      <div
                        className="cursor-pointer"
                        onClick={() => handleDeleteSubtitleModal(_data)}
                      >
                        <img
                          className="delete-icon"
                          src={DeleteIcon}
                          alt="delete"
                        />
                      </div>
                      <div className="time">
                        OUT
                        {/* <input className='input-time' onChange={(e) => handleTimeChange(index, 'end', e.target.value)} value={secondsToMinutesAndHours(_data?.end)} /> */}
                        <Flatpickr
                          className="custom-flat-picker"
                          value={secondsToMinutesAndHours(_data?.end)}
                          onChange={(e, dateStr) =>
                            handleTimeChange(index, "end", dateStr)
                          }
                          options={{
                            enableSeconds: true,
                            enableTime: true,
                            noCalendar: true,
                            time_24hr: true,
                            hourIncrement: 1,
                            minuteIncrement: 1,
                            dateFormat: "H:i:S",
                            minTime: secondsToMinutesAndHours(_data?.start),
                            maxTime: secondsToMinutesAndHours(
                              selectedVideoInfo?.duration
                            ),
                            inline: true,
                          }}
                        />
                      </div>
                    </div>
                    <textarea
                      className="text-area-subtitle"
                      value={_data?.text}
                      onChange={(e) =>
                        handleTextareaChange(index, e.target.value)
                      }
                    ></textarea>
                  </div>
                );
              })}
          </div>
        </>
      )}
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDeleteSubtitle}
        onCloseClick={() => {
          setShowDeleteModal(false);
        }}
        deleteType={true}
      />
    </div>
  );
};

export default ManualTransSection;
