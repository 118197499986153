import { Modal, ModalBody, Table } from "reactstrap";
import { SegmentModelProps } from "../../../Common/interface/types";
import Select from "react-select";
import { useState } from "react";
import {
  getLangListByVideoType,
  secondsToMinutesAndHours,
} from "../../../Common/helper/helper";
import { VIDEO_TYPE } from "../../../Constants/Common/Data";

const SegmentModel = (props: SegmentModelProps) => {
  const { show, onCloseClick, onSubmit, segmentData } = props;
  const [selectedLang, setSelectedLang] = useState<any>({});
  const [isValidationPassed, setIsValidationPassed] = useState(false);

  const handleAddLang = (selectedLang: any, segmentId: number) => {
    setIsValidationPassed(false);
    setSelectedLang((prevSelectedLang: any) => ({
      ...prevSelectedLang,
      [segmentId]: selectedLang,
    }));
  };

  const createOutputArray = () => {
    const outputArray: any = [];

    segmentData.forEach((_data: any, index: number) => {
      const id = index + 1;
      const selectedLangValue = selectedLang[id];

      if (selectedLang) {
        outputArray.push({
          startTime: Math.round(_data?.start),
          endTime: Math.round(_data?.end),
          language: selectedLangValue?.value.toLowerCase(),
        });
      }
    });

    return outputArray;
  };

  const validateSelection = () => {
    const tempArray = createOutputArray();
    const isValid = tempArray.every((obj: any) => obj.language !== undefined);
    setIsValidationPassed(!isValid);
    return isValid;
  };

  const handleSubmit = async () => {
    if (validateSelection()) {
      onSubmit(createOutputArray());
      onCloseClick();
    }
  };

  const handleModalClose = () => {
    setSelectedLang({});
    setIsValidationPassed(false);
    onCloseClick();
  };

  return (
    <Modal
      fade={true}
      size="lg"
      isOpen={show}
      toggle={handleModalClose}
      centered={true}
    >
      <ModalBody className="segment-model py-3 px-5">
        <div className="mt-2 text-center segment-section-modal">
          {segmentData && segmentData.length > 0 && (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Select language</th>
                  </tr>
                </thead>
                {segmentData.map((_segment, index) => {
                  return (
                    <tbody>
                      <tr className="segment-row">
                        <td>{index + 1}</td>
                        <td>{secondsToMinutesAndHours(_segment.start)}</td>
                        <td>{secondsToMinutesAndHours(_segment.end)}</td>
                        <td>
                          <Select
                            className="lang-select"
                            onChange={(selectedLang: any) =>
                              handleAddLang(selectedLang, index + 1)
                            }
                            maxMenuHeight={100}
                            value={selectedLang[index + 1]}
                            options={getLangListByVideoType(VIDEO_TYPE.UPLOAD)}
                            placeholder="Select Language"
                          />
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
            </>
          )}
        </div>
        <div className="error">
          {isValidationPassed && "Please select a language for each segment"}
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={handleModalClose}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm btn-danger "
            id="delete-record"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SegmentModel;
