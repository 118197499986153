import { ToastPosition } from "react-hot-toast";

export const RESOURCE_STATUS = {
  PENDING: 0,
  PROCESSING: 1,
  COMPLETED: 2,
  INIL_PROCESSING: "1",
};

export const SortData = [
  {
    value: "createdAt desc",
    label: "Latest",
    icon: "ri-sort-asc",
  },
  {
    value: "createdAt asc",
    label: "Older",
    icon: "ri-sort-desc",
  },
];

export const UPLOAD_TAB_TYPE = {
  UPLOAD: 0,
  OPERATION: 1,
};

export const TAB_TYPE = {
  VIDEO: "VIDEO",
  FILE: "FILE",
  SEARCH: "SEARCH",
  SUBTITLE: "SUBTITLE",
  SHARE: "SHARE",
  WAVE: "WAVE",
  UPLOAD: "UPLOAD",
  LIVE_STREAM: "LIVE_STREAM",
};

export const STREAM_TAB_TYPE = {
  LIVE_STREAM_LIST: "LIVE_STREAM_LIST",
  LIVE_STREAM_EDITOR: "LIVE_STREAM_EDITOR",
  TEXT_EDITOR: "TEXT_EDITOR",
  TRANSCRIPT_EDITOR: "TRANSCRIPT_EDITOR",
  SCRIPT_EDITOR: "SCRIPT_EDITOR",
};

export const TAB_TYPE_DATA = [
  TAB_TYPE.VIDEO,
  TAB_TYPE.FILE,
  TAB_TYPE.SEARCH,
  TAB_TYPE.SUBTITLE,
  TAB_TYPE.SHARE,
  TAB_TYPE.WAVE,
  TAB_TYPE.UPLOAD,
];

export const VIDEO_STATUS = {
  UPLOADED: 0,
  PROCESSING: 1,
  COMPLETED: 2,
  NOT_PROCESSED: 3,
  NOT_TRANSCRIBED: 4,
  REPROCESS_VIDEO: 5,
  REPROCESS_VIDEO_COMPLETED: 6,
  REPROCESS_VIDEO_ERROR: 7,
  COMPRESS: 8,
  UPLOAD_FAILED: 9,
  TEMP_UPLOAD: 10,
};

export const VIDEO_STATUS_TYPE = {
  UPLOADED_SUCCESSFULLY: "Uploaded successfully",
  UPLOADING: "Uploading...",
  UPLOADING_IN_PROCESS: "Uploading in process...",
  COMPRESS_IN_PROCESS: "Compressing...",
  COMPLETED: "Completed",
};

export const VIDEO_RESPONSE_STATUS = {
  UPLOADING: 1,
  PROCESSING: 2,
  COMPRESSING: 3,
  COMPLETED: 4,
};

export const VIDEO_CARD_TYPE = {
  SHARE: "SHARE",
  INFO: "INFO",
};

export const AUTO_DETECT_FORM_TYPE = {
  SAVE: "SAVE",
  START: "START",
  EDIT: "EDIT",
};

export const allowedExtensions = ["mp4", "mov", "mxf", "mpeg", "m4v"];

export const LAYOUT_TYPES = {
  LAYOUT1: {
    value: [TAB_TYPE.FILE, TAB_TYPE.VIDEO],
    label: 1,
  },
  LAYOUT2: {
    value: [TAB_TYPE.FILE, TAB_TYPE.VIDEO, TAB_TYPE.WAVE],
    label: 2,
  },
  LAYOUT3: {
    value: [TAB_TYPE.UPLOAD, TAB_TYPE.FILE, TAB_TYPE.VIDEO, TAB_TYPE.WAVE],
    label: 3,
  },
  LAYOUT4: {
    value: [TAB_TYPE.SUBTITLE, TAB_TYPE.FILE, TAB_TYPE.VIDEO, TAB_TYPE.WAVE],
    label: 4,
  },
  LAYOUT5: {
    value: [TAB_TYPE.VIDEO, TAB_TYPE.SUBTITLE, TAB_TYPE.WAVE],
    label: 5,
  },
};

export const MAX_FILE_NAME_LENGTH = 100;

export const LIVE_SUBTITLE_TYPE = 2; //THis Number identify for subtitle LIVE

export const PAUSE_MODAL_TIME_LIMIT = 60; //sec

export const LIVE_VIDEO_TYPE = {
  YOUTUBE: 1,
  OTHER_RESOURCES: 2,
};

export const LIVE_VIDEO_STATUS = {
  NOT_STARTED: 0,
  LIVE: 1,
  PAUSE: 2,
  ERROR: 3,
  LIVE_ENDED: 4,
  RE_START: 5,
};

export const TRANSCRIBED_TYPE = {
  UPLOADED_VIDEO: 1,
  LIVE_STREAM: 2,
};

export const CONFIRMATION_MODAL_TYPE = {
  INFO: "INFO",
  ACTION: "ACTION",
};

export const ADD_NEW_LANG = "NEW_LANG";
export const ERROR_CODE = {
  DELETE: 1,
};

export const TOASTER_CONFIG: {
  position: ToastPosition;
  reverseOrder: boolean;
  gutter: number;
  containerClassName: string;
  containerStyle: any;
  toastOptions: any;
} = {
  position: "top-right",
  reverseOrder: false,
  gutter: 8,
  containerClassName: "",
  containerStyle: {},
  toastOptions: {
    className: "",
    duration: 5000,
    style: {
      background: "#ffff",
      color: "black",
    },
    success: {
      duration: 3000,
    },
    error: {
      duration: 3000,
    },
  },
};

export const VIDEO_TYPE = {
  LIVE: "live",
  UPLOAD: "upload",
};
