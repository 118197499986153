import React, { useState } from "react";
import Select from "react-select";
import "./SubtitleModal.scss";
import ModalLayout from "../../../Common/ModalLayout";
import toast from "react-hot-toast";
import { MESSAGE } from "../../../../Constants/Messages";
import {
  getLangListByVideoType,
  isBlank,
} from "../../../../Common/helper/helper";
import { VIDEO_TYPE } from "../../../../Constants/Common/Data";

const SubTitleModal = (props: any) => {
  const { setSubTitleOpen, setLanguage } = props;
  const [data, setData] = useState<object>({});
  const [addLangInputValue, setAddLangInputValue] = useState("");
  const langList = getLangListByVideoType(VIDEO_TYPE.LIVE);
  const maxLength = 10;

  const handleSubmit = () => {
    if (!isBlank(data)) {
      setLanguage(data);
    } else {
      toast.error(MESSAGE.ERROR.selectLang);
    }
  };

  const handleLangInputChange = (inputValue: string) => {
    if (inputValue.length > maxLength) {
      setAddLangInputValue(inputValue.substr(0, maxLength));
    } else {
      setAddLangInputValue(inputValue);
    }
  };

  return (
    <ModalLayout
      isShowing={true}
      hide={() => setSubTitleOpen(false)}
      style={{ background: "rgba(26, 26, 26, 1)" }}
    >
      <div className="subtitle-modal-main">
        <h3 className="title">Add a new subtitle</h3>
        <h6 className="sub-heading">Choose a language in the list below</h6>
        <div className="select-container">
          <Select
            onChange={(e: any) => setData(e)}
            options={langList}
            name="language"
            className="lang-change"
            onInputChange={handleLangInputChange}
            inputValue={addLangInputValue}
            maxMenuHeight={200}
            placeholder={"Search Language"}
          />
        </div>
        <div className="button-container">
          <div onClick={() => setSubTitleOpen(false)} className="action-btn">
            Cancel
          </div>
          <div
            onClick={handleSubmit}
            className={`action-btn ${isBlank(data) ? "disable" : ""}`}
          >
            Okay
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default SubTitleModal;
