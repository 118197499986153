import { useEffect, useState } from "react";
import Select from "react-select";
import useTranscriptionStore from "../../../Store/TranscriptionStore/TranscriptionStore";
import { VideoSegmentsTypeDefaultValue } from "../../../Common/interface/defaultValue";
import {
  PostReviewTextPayload,
  ReviewInfoDefaultType,
  TextDetailsApiPayload,
  VideoInfo,
  VideoStatusApiPayload,
  VideoTextType,
  textType,
} from "../../../Common/interface/types";
import AddIcon from "../../../assets/images/New/Add.svg";
import EditIcon from "../../../assets/images/New/Edit.svg";
import DeleteIcon from "../../../assets/images/New/Delete.svg";
import RightIcon from "../../../assets/images/New/Right.svg";
import CheckCircleIcon from "../../../assets/images/New/CheckCircle.svg";
import {
  postFetchTextDetails,
  postReviewText,
  postSetVideoStatus,
  postUpdateSubTittleText,
} from "../../../ApiService/ApiCall";
import { RESOURCE_STATUS, VIDEO_TYPE } from "../../../Constants/Common/Data";
import {
  createVersionArray,
  getFlagByLang,
  getLangCode,
  getLangListByVideoType,
  getLangName,
  secondsToMinutesAndHours,
} from "../../../Common/helper/helper";
import DeleteModal from "../Common/DeleteModal";
import CopyIcon from "../../../assets/images/New/Copy.svg";
import DownloadIcon from "../../../assets/images/New/Download.svg";
import useVideoRefStore from "../../../Store/VideoStore/VideoStore";
import { handleDownload } from "../../../Common/helper/helper";
import VerifyIcon from "../../../assets/images/New/verifyIcon.png";
import useUserStore from "../../../Store/UserStore/UserStore";
import { PopoverBody, Tooltip, UncontrolledPopover } from "reactstrap";
import { MESSAGE } from "../../../Constants/Messages";
import Toast from "../../../Common/helper/toasterService";
import { UPLOAD_DEEPL_TRANSLATE } from "../../../Constants/Environment";

const RightSection = () => {
  const [langAvailable, setLangAvailable] = useState(false);
  const [rightTextResponse, setRightTextResponse] = useState<textType[]>([]);
  const [, setSelectedLang] = useState<any>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<any>([]);
  const [selectedLangValue, setSelectedLangValue] = useState("");
  const [defaultShow, setDefaultShow] = useState(true);
  const [versionData, setVersionData] = useState<any>([]);
  const [updatedLangList, setUpdatedLangList] = useState(
    getLangListByVideoType(VIDEO_TYPE.UPLOAD)
  );
  const [selectedVersion, setSelectedVersion] = useState<any>();
  const [updatedTextResponse, setUpdatedTextResponse] = useState<textType[]>(
    []
  );
  const [isEdit, setIsEdit] = useState(false);
  const [addLangStatus, setAddLangStatus] = useState(false);
  const [selectedSubtitle, setSelectedSubtitle] = useState<textType>(
    VideoSegmentsTypeDefaultValue
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [textToCopy, setTextToCopy] = useState("");
  const [textToDownload, setTextToDownload] = useState("");
  const [reviewStatus, setReviewStatus] = useState(false);
  const [showReviewOption, setShowReviewOption] = useState(false);
  const [reviewInfo, setReviewInfo] = useState<ReviewInfoDefaultType>({
    firstName: "",
    lastName: "",
  });
  const [isVersionView, setIsVersionView] = useState(false);
  const [versionLangList, setVersionLangList] = useState<any[]>([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [addLangInputValue, setAddLangInputValue] = useState("");
  const [, setSummaryBtnStatus] = useState(false);
  const [, setSummaryText] = useState("");
  const [, setVersionTextId] = useState("");
  const maxLength = 10;

  const {
    selectedVideoId,
    setLoading,
    langList,
    videoPlayerTime,
    rightSubtitleData,
    transcriptionVideoList,
    setLangList,
    setTranscriptionList,
    setRightSubtitleData,
    setSubtitleData,
    subtitleData,
    setLeftSubtitleData,
    setIsDataUpdate,
    isDataUpdate,
    versionTextData,
    setVersionTextData,
    versionId,
  } = useTranscriptionStore((state: any) => ({
    selectedVideoId: state.selectedVideoId,
    setLoading: state.setLoading,
    langList: state.langList,
    videoPlayerTime: state.videoPlayerTime,
    rightSubtitleData: state.rightSubtitleData,
    transcriptionVideoList: state.transcriptionVideoList,
    setLangList: state.setLangList,
    setTranscriptionList: state.setTranscriptionList,
    setRightSubtitleData: state.setRightSubtitleData,
    setSubtitleData: state.setSubtitleData,
    subtitleData: state.subtitleData,
    setLeftSubtitleData: state.setLeftSubtitleData,
    setIsDataUpdate: state.setIsDataUpdate,
    isDataUpdate: state.isDataUpdate,
    versionTextData: state.versionTextData,
    setVersionTextData: state.setVersionTextData,
    versionId: state.versionId,
  }));

  const { currentUser } = useUserStore((state: any) => ({
    currentUser: state.currentUser,
  }));

  const { playerRef } = useVideoRefStore((state: any) => ({
    playerRef: state.playerRef,
  }));

  const setSegmentData = (data: any[]) => {
    let tempData: any[] = [];
    data.forEach((_item) => {
      tempData.push({ ..._item });
    });
    const text = tempData
      ?.map((segment) => {
        const { text } = segment;
        return `${text}\n`;
      })
      .join("\n");

    const formattedText = tempData
      ?.map((segment: { end: number; start: number; text: any }) => {
        const startTime = secondsToMinutesAndHours(segment.start);
        const endTime = secondsToMinutesAndHours(segment.end);
        return `IN ${startTime} - OUT ${endTime}\n${segment.text}\n`;
      })
      .join("\n");

    setRightTextResponse(tempData);
    setTextToCopy(text);
    setTextToDownload(formattedText);
  };

  const setVersionLangListOption = (data: any[]) => {
    const temp: any[] = [];
    data.forEach((_data) => {
      temp.push({
        value: _data.language[0],
        label: getLangName(_data.language[0]),
      });
    });
    setSelectedLang(temp[0]);
    setSelectedLanguage(temp[0]);
    setVersionLangList(temp);
    setSelectedLangValue(temp[0].label);
  };

  useEffect(() => {
    if (isDataUpdate) {
      setIsDataUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoId, versionId]);

  useEffect(() => {
    if (!isDataUpdate) {
      if (
        versionTextData &&
        versionTextData.length === 0 &&
        versionId === "" &&
        rightSubtitleData &&
        rightSubtitleData?.segments &&
        rightSubtitleData?.segments.length > 0
      ) {
        setSegmentData(rightSubtitleData?.segments);
        setReviewStatus(rightSubtitleData?.isReviewed);
        setReviewInfo(rightSubtitleData?.reviewedBy);
        setUpdatedTextResponse(rightSubtitleData?.segments);
        createVersion(rightSubtitleData?.versions.length || 0);
        setSelectedLangValue(getLangName(rightSubtitleData?.language[0]));
        if (rightSubtitleData?.summary) {
          setSummaryText(rightSubtitleData?.summary);
        } else {
          setSummaryText("");
        }
        setVersionTextId("");
        setLangAvailable(false);
        setShowReviewOption(true);
      } else if (
        versionId &&
        versionId !== "" &&
        versionTextData &&
        versionTextData.length > 0
      ) {
        setSegmentData(versionTextData[0]?.segments);
        setShowReviewOption(false);
        setUpdatedTextResponse(versionTextData[0]?.segments);
        setVersionLangListOption(versionTextData);
        setVersionTextId(versionTextData[0]?._id);
        if (versionTextData[0]?.summary) {
          setSummaryText(versionTextData[0]?.summary);
        } else {
          setSummaryText("");
        }
        createVersion(0);
        setLangAvailable(false);
      } else {
        setShowReviewOption(false);
        setRightTextResponse([]);
        setUpdatedTextResponse([]);
        setLangAvailable(true);
      }
      setSelectedLang([]);
      setIsDataUpdate(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideoId, rightSubtitleData, versionTextData, versionId]);

  useEffect(() => {
    if (versionId && versionId === "") {
      const tempArray = langList.map((obj: any) => obj.value);
      if (tempArray && tempArray.length > 0) {
        const updatedWithDisabled = updatedLangList.map((lang: any) => {
          if (tempArray.includes(lang.value)) {
            return { ...lang };
          }
          return lang;
        });

        setUpdatedLangList(updatedWithDisabled);

        setSelectedLang([
          { value: langList[0].value, label: langList[0].label },
        ]);
        setSelectedLanguage([
          { value: langList[0].value, label: langList[0].label },
        ]);
        if (selectedLangValue === "") {
          setSelectedLangValue(langList[0].label);
        }
      } else {
        setUpdatedLangList(getLangListByVideoType(VIDEO_TYPE.UPLOAD));
        setSelectedLang([]);
        setSelectedLanguage([]);
        setSelectedLangValue("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langList, selectedVideoId, versionId]);

  const getTextDetails = async (language: string) => {
    setLoading(true);
    let res: VideoTextType | any = await getTextDetailsApi(language);

    if (res && res[0] && versionId !== "") {
      setIsDataUpdate(true);
      if (res && res[0]) {
        setLeftSubtitleData(res[0]);
        setSubtitleData(res);
        const tempVersionTextData = res[0]?.versions.filter(
          (_data: any) => versionId === _data._id
        );
        setSegmentData(
          tempVersionTextData[0]?.versionTexts[
            tempVersionTextData[0]?.versionTexts.length - 1
          ].segments
        );
        setVersionTextData(tempVersionTextData[0]?.versionTexts);
        setVersionTextId(tempVersionTextData[0]?._id);
        const temp: any[] = [];
        tempVersionTextData[0]?.versionTexts.forEach((_data: any) => {
          temp.push({
            value: _data.language[0],
            label: getLangName(_data.language[0]),
          });
        });
        setVersionLangList(temp);
      }
      if (res && res[1]) {
        setRightSubtitleData(res[1]);
      } else {
        setRightSubtitleData("");
      }
      setLoading(false);
      return true;
    } else if (res && res[0] && versionId === "") {
      setSegmentData(res[0]?.segments);
      setRightSubtitleData(res[0]);
      setSubtitleData([...subtitleData, res[0]]);
      setSummaryBtnStatus(false);
      if (res && res[0]?.summary) {
        setSummaryText(() => res[0]?.summary);
      } else {
        setSummaryText("");
      }
      setVersionTextId("");
      setLoading(false);
    } else {
      setLoading(false);
      return false;
    }
  };

  const getTextDetailsApi = async (language: string) => {
    try {
      const textDetailsApiPayload: TextDetailsApiPayload = {
        productionResourceId: selectedVideoId,
      };

      if (versionId === "") {
        textDetailsApiPayload.languages = [language?.toLowerCase()];
      }

      let res = await postFetchTextDetails(textDetailsApiPayload);
      return res;
    } catch (err: any) {
      Toast.showError(err?.msg);
      console.log(err);
    }
  };

  const handleAddLang = async (selectedValue: any) => {
    setSelectedLang(selectedValue);
    setSelectedLanguage(selectedValue);
    setSelectedLangValue(selectedValue.label);
    setLoading(true);
    const targetObject: VideoInfo = transcriptionVideoList.find(
      (item: VideoInfo) => item._id === selectedVideoId
    );
    if (targetObject) {
      // const resLang = targetObject.languages.find((item: lang) =>
      //   item.language.includes(selectedValue.value.toLowerCase())
      // );
      // if (versionId !== "") {
      const res: any = await setVideoStatusApi(
        selectedValue?.value.toLowerCase()
      );
      if (res && res?.status === true) {
        let resDetails = await getTextDetails(
          selectedValue?.value.toLowerCase()
        );
        // if (versionId === "") {
        targetObject.languages.push({
          _id: "",
          language: [selectedValue?.value?.toLowerCase()],
          status: resDetails
            ? RESOURCE_STATUS.COMPLETED
            : RESOURCE_STATUS.PROCESSING,
          reviewStatus: "0",
        });
        setLangList(targetObject?.languages);
        // }

        setTranscriptionList(transcriptionVideoList);

        setLoading(false);
        setAddLangStatus(false);
        setLangAvailable(false);
        setDefaultShow(true);
        Toast.showSuccess(
          `${MESSAGE.SUCCESS.translationNow} ${selectedValue.label}`
        );
      } else {
        setLoading(false);
        setAddLangStatus(false);
      }
      // } else {
      //   setLoading(false);
      //   toast.error("Transcription already exist");
      // }
    }
  };

  const setVideoStatusApi = async (language: string) => {
    const videoStatusApiPayload: VideoStatusApiPayload = {
      productionResourceId: selectedVideoId,
      language: language,
      type: 4,
      deeplTranslate: UPLOAD_DEEPL_TRANSLATE,
    };

    if (versionId && versionId !== "") {
      videoStatusApiPayload.versionId = versionId;
    }
    try {
      const res = await postSetVideoStatus(videoStatusApiPayload);
      return res;
    } catch (err: any) {
      Toast.showError(err?.msg);
      console.log(err);
    }
  };
  const handleLangChange = (selectedValue: any) => {
    setSelectedLanguage(selectedValue);
    setSelectedLangValue(getLangName(selectedValue.value));
    getTextDetails(selectedValue?.value);
    setDefaultShow(true);
  };

  const handleVersion = (selectedValue: any) => {
    setSelectedVersion(selectedValue);
    if (selectedValue.value === "default") {
      setSegmentData(rightSubtitleData?.segments);
      setReviewStatus(rightSubtitleData?.isReviewed);
      setReviewInfo(rightSubtitleData?.reviewedBy);
      setIsVersionView(true);
    } else {
      setSegmentData(
        rightSubtitleData?.versions[selectedValue.value]?.segments
      );
      setReviewStatus(
        rightSubtitleData?.versions[selectedValue.value]?.isReviewed
      );
      setReviewInfo(
        rightSubtitleData?.versions[selectedValue.value]?.reviewedBy
      );
      setIsVersionView(false);
    }
  };

  const createVersion = (ver: number) => {
    if (ver > 0) {
      const verArr = createVersionArray(ver);
      setVersionData(verArr);
      setSelectedVersion(verArr[0]);
    } else {
      setVersionData([]);
      setSelectedVersion("");
    }
  };

  const handleSubmit = () => {
    setIsEdit(false);
    setReviewStatus(false);
    callTextUpdateApi();
  };

  const callTextUpdateApi = async () => {
    try {
      setLoading(true);
      const res: any = await postUpdateSubTittleText({
        id: rightSubtitleData?._id,
        segments: updatedTextResponse,
      });
      setIsEdit(false);
      Toast.showSuccess(MESSAGE.SUCCESS.documentUpdated);
      AddVersion(res?.result);
      setLoading(false);
    } catch (err: any) {
      console.log(err);
      setLoading(false);
      Toast.showError(err?.msg || "something went wrong");
    }
  };

  const AddVersion = (res: any) => {
    const versionData = createVersionArray(res?.version);
    setVersionData(versionData);
    setSelectedVersion(versionData[versionData.length - 1]);
    rightSubtitleData.versions.push(res);
  };

  const handleTextareaChange = (index: number, newText: any) => {
    const updatedTextResponseCopy: any = [...updatedTextResponse];
    updatedTextResponseCopy[index] = {
      ...updatedTextResponseCopy[index],
      text: newText,
    };
    setUpdatedTextResponse(updatedTextResponseCopy);
    setRightTextResponse(updatedTextResponseCopy);
  };

  const handleDeleteSubtitleModal = (subtitleInfo: any) => {
    setSelectedSubtitle(subtitleInfo);
    setShowDeleteModal(true);
  };

  const handleDeleteSubtitle = async () => {
    try {
      setLoading(true);

      const isLastSubtitle = rightTextResponse.length === 1;

      if (isLastSubtitle) {
        Toast.showCustom(MESSAGE.WARNING.lastSubtitleDelete);
        setLoading(false);
        setShowDeleteModal(false);
        return;
      } else {
        if (selectedSubtitle) {
          const updatedSegments = rightTextResponse.filter(
            (segment) => segment !== selectedSubtitle
          );

          setRightTextResponse(updatedSegments);
          setUpdatedTextResponse(updatedSegments);

          Toast.showSuccess(
            `${MESSAGE.SUCCESS.transcriptionDeleted} ${selectedSubtitle.start}`
          );
        }
        setShowDeleteModal(false);
      }
      setLoading(false);
    } catch (err: any) {
      Toast.showError(err.msg || "Something went wrong");
      console.error(err);
      setShowDeleteModal(false);
      setLoading(false);
    }
  };

  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => Toast.showSuccess(MESSAGE.SUCCESS.textCopied))
      .catch((err) => Toast.showError("Failed to copy"));
  };

  const handleDownloadClick = () => {
    try {
      handleDownload(textToDownload);
      Toast.showSuccess(`Document in ${selectedLangValue} downloaded : TXT`);
    } catch (error) {
      Toast.showError(
        `Download error: Please check the error : ${error} and retry`
      );
    }
  };

  const handleTextBoxClick = (startTime: number) => {
    if (playerRef && playerRef != null) {
      playerRef.currentTime(startTime + 1);
    }
  };

  const handleReview = async () => {
    setLoading(true);
    const res = await reviewApiCall();
    if (res) {
      Toast.showSuccess(MESSAGE.SUCCESS.textVerified);
      setReviewStatus(true);
    }
    setLoading(false);
  };

  const reviewApiCall = async () => {
    let payload: PostReviewTextPayload = {
      productionResourceId: selectedVideoId,
      textResourceId: rightSubtitleData?._id,
      language: [getLangCode(selectedLangValue)],
    };
    if (selectedVersion && selectedVersion.value !== "default") {
      payload.versionId =
        rightSubtitleData?.versions[selectedVersion.value]._id;
      const tempData: any = { ...rightSubtitleData };
      const selectedVersionValue = selectedVersion.value;
      tempData.versions[selectedVersionValue] = {
        ...tempData.versions[selectedVersionValue],
        isReviewed: true,
        reviewedBy: {
          firstName: currentUser?.firstName,
          lastName: currentUser?.lastName,
        },
      };
      setRightSubtitleData(tempData);
      setReviewInfo({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
      });
    } else {
      const tempData: any = { ...rightSubtitleData };
      tempData.isReviewed = true;
      tempData.reviewedBy = {
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
      };
      setRightSubtitleData(tempData);
      setReviewInfo({
        firstName: currentUser?.firstName,
        lastName: currentUser?.lastName,
      });
    }
    setIsDataUpdate(true);
    try {
      let res = await postReviewText(payload);
      return res;
    } catch (err: any) {
      console.log(err);
      Toast.showError(err?.msg);
    }
  };

  // const handleSaveSummary = async () => {
  //   if (summaryText && summaryText.trim().length > 0) {
  //     await callSummeryUpdateApi();
  //   } else {
  //     toast.error("Empty text box: Please enter text before saving.");
  //   }
  // };

  // const callSummeryUpdateApi = async () => {
  //   try {
  //     setLoading(true);
  //     const payload: any = {
  //       summary: summaryText.trim(),
  //     };
  //     if (versionId && versionTextId) {
  //       payload.translatedTextId = versionTextId;
  //     } else {
  //       payload.id = rightSubtitleData?._id;
  //     }
  //     const res: any = await postUpdateSubTittleText(payload);
  //     toast.success(res.message);
  //     setLoading(false);
  //   } catch (err: any) {
  //     console.log(err);
  //     setLoading(false);
  //     toast.error(err?.msg || "something went wrong");
  //   }
  // };

  const handleLangInputChange = (inputValue: string) => {
    if (inputValue.length > maxLength) {
      setAddLangInputValue(inputValue.substr(0, maxLength));
    } else {
      setAddLangInputValue(inputValue);
    }
  };

  const handleVersionChange = (selectedValue: any) => {
    setSelectedLanguage(selectedValue);
    setSelectedLangValue(getLangName(selectedValue.value));

    if (selectedValue.value === "default") {
      setSegmentData(rightSubtitleData?.segments);
      setUpdatedTextResponse(rightSubtitleData?.segments);
      setDefaultShow(true);
    } else {
      const selectedVersionData = rightSubtitleData?.versions.find(
        (version: any) => version._id === selectedValue.value
      );
      if (selectedVersionData) {
        setSegmentData(selectedVersionData.segments);
        setUpdatedTextResponse(selectedVersionData.segments);
        setDefaultShow(true);
      }
    }
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div className="right-section">
      {langAvailable || addLangStatus ? (
        <>
          <div className="select-lang-outer">
            <Select
              className="lang-select"
              onChange={handleAddLang}
              options={updatedLangList}
              maxMenuHeight={200}
              onInputChange={handleLangInputChange}
              inputValue={addLangInputValue}
              placeholder="Select Language"
            />
            {addLangStatus && (
              <div
                className="close-btn"
                onClick={() => {
                  setAddLangStatus(false);
                  setDefaultShow(true);
                }}
              >
                close
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <div className="action">
            {!addLangStatus && !defaultShow && versionId === "" ? (
              <div className="multi-lang-outer">
                <div className="lang">
                  <Select
                    className="multi-lang-list"
                    onChange={handleLangChange}
                    value={selectedLanguage}
                    options={langList}
                    placeholder="Language"
                  />
                </div>
                <div
                  className="lang-add"
                  onClick={() => {
                    setAddLangStatus(true);
                  }}
                >
                  <img src={AddIcon} alt="add" />
                </div>
              </div>
            ) : !addLangStatus && !defaultShow && versionId !== "" ? (
              <div className="multi-lang-outer">
                <div className="lang">
                  <Select
                    className="multi-lang-list"
                    onChange={handleVersionChange}
                    value={selectedLanguage}
                    options={versionLangList}
                    placeholder="Language"
                  />
                </div>
                <div
                  className="lang-add"
                  onClick={() => {
                    setAddLangStatus(true);
                  }}
                >
                  <img src={AddIcon} alt="add" />
                </div>
              </div>
            ) : defaultShow ? (
              <div className="default-lang-outer">
                <div className="lang">
                  {selectedLangValue && (
                    <>
                      {getFlagByLang(selectedLangValue) && (
                        <>
                          <img
                            className="flag-icon cursor-pointer"
                            id="flag-lang-right"
                            src={`https://unpkg.com/language-icons/icons/${getFlagByLang(
                              selectedLangValue
                            )}.svg`}
                            alt="flag"
                          />
                          <Tooltip
                            placement="bottom"
                            isOpen={tooltipOpen}
                            autohide={true}
                            target={`flag-lang-right`}
                            toggle={toggleTooltip}
                          >
                            {getLangName(selectedLangValue)}
                          </Tooltip>
                        </>
                      )}
                      {/* {selectedLangValue} */}
                    </>
                  )}
                </div>
                <div
                  className="lang-add"
                  onClick={() => {
                    setDefaultShow(false);
                  }}
                >
                  <img src={EditIcon} alt="edit" />
                </div>
              </div>
            ) : null}
            <div className="action-copy-btn-outer">
              <div className="copy" title="copy" onClick={handleCopy}>
                <img width={"15px"} src={CopyIcon} alt="copy" />
              </div>
              <div
                className="download"
                title="download"
                onClick={handleDownloadClick}
              >
                <img width={"15px"} src={DownloadIcon} alt="download" />
              </div>
            </div>
            {reviewStatus && showReviewOption && (
              <>
                <div>
                  <img
                    className="verify-icon"
                    src={VerifyIcon}
                    alt="verify"
                    id="verify"
                  />
                </div>
                <UncontrolledPopover
                  target="verify"
                  placement="top"
                  trigger="legacy"
                >
                  <PopoverBody>
                    <div className="pop-over-section">
                      <div className="text-center">
                        Reviewed By :
                        {" " +
                          reviewInfo?.firstName +
                          " " +
                          reviewInfo?.lastName}
                      </div>
                    </div>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
            {rightSubtitleData &&
              rightSubtitleData.versions &&
              rightSubtitleData.versions.length > 0 &&
              versionId === "" && (
                <div className="version-lang-outer">
                  <Select
                    className="version-list"
                    onChange={handleVersion}
                    value={selectedVersion}
                    options={versionData}
                    placeholder="Version"
                  />
                  {!isEdit && isVersionView && (
                    <div
                      className="version-edit"
                      onClick={() => setIsEdit(true)}
                    >
                      <img src={EditIcon} alt="edit" />
                    </div>
                  )}
                  {isEdit && (
                    <div className="version-edit" onClick={handleSubmit}>
                      <img src={CheckCircleIcon} alt="submit" />
                    </div>
                  )}
                </div>
              )}
            {rightSubtitleData &&
              rightSubtitleData?.versions &&
              rightSubtitleData?.versions.length === 0 &&
              versionId === "" &&
              !isEdit && (
                <div
                  className="version-edit-single"
                  onClick={() => setIsEdit(true)}
                >
                  <img src={EditIcon} alt="edit" />
                </div>
              )}
            {rightSubtitleData &&
              rightSubtitleData?.versions &&
              rightSubtitleData?.versions.length === 0 &&
              isEdit && (
                <div className="version-edit-single" onClick={handleSubmit}>
                  <img src={CheckCircleIcon} alt="submit" />
                </div>
              )}
          </div>
          <div className={`subtitle-text-main-outer`} id="right-text-container">
            {rightTextResponse &&
              rightTextResponse?.length > 0 &&
              rightTextResponse?.map((_data: textType, index: any) => {
                return (
                  <div className="subtitle-text-outer">
                    <div className="heading">
                      <div className="time">
                        IN{" "}
                        {_data?.start && _data?.start > 0
                          ? secondsToMinutesAndHours(_data?.start)
                          : "00:00:00"}
                      </div>
                      {isEdit && (
                        <div
                          className="cursor-pointer"
                          onClick={() => handleDeleteSubtitleModal(_data)}
                        >
                          <img
                            className="delete-icon"
                            src={DeleteIcon}
                            alt="delete"
                          />
                        </div>
                      )}
                      <div className="time">
                        OUT{" "}
                        {_data?.end && _data?.end > 0
                          ? secondsToMinutesAndHours(_data?.end)
                          : "00:00:00"}
                      </div>
                    </div>
                    {!isEdit ? (
                      <div
                        className={`subtitle-text cursor-pointer ${
                          _data?.start <= videoPlayerTime &&
                          _data?.end >= videoPlayerTime
                            ? "active-text"
                            : ""
                        }`}
                        onClick={() => handleTextBoxClick(_data?.start)}
                      >
                        {_data?.text}
                      </div>
                    ) : (
                      <textarea
                        className="text-area-subtitle"
                        defaultValue={_data?.text}
                        onChange={(e) =>
                          handleTextareaChange(index, e.target.value)
                        }
                      ></textarea>
                    )}
                  </div>
                );
              })}
          </div>

          {/* {summaryBtnStatus && (
            <div className="summery-box">
              <div>
                <label>Description</label>
                <textarea
                  name="summary-text"
                  key={selectedLangValue}
                  className="text-area-subtitle"
                  value={summaryText}
                  onChange={(e) => setSummaryText(e.target.value)}
                />
                <div className="check-btn-outer">
                  <div className="check-btn" onClick={handleSaveSummary}>
                    Save
                  </div>
                </div>
              </div>
            </div>
          )} */}

          <div className="subtitle-footer">
            {!reviewStatus && showReviewOption && (
              <div className="check-btn" onClick={handleReview}>
                <img src={RightIcon} alt="send" />
                Review
              </div>
            )}
            {/* <div className="form-check form-switch form-switch-success form-switch-lg">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                onChange={() => {
                  setSummaryBtnStatus(!summaryBtnStatus);
                }}
                checked={summaryBtnStatus}
                id="flexSwitchCheckDefault"
              />
              <Label className="form-check-label" for="flexSwitchCheckDefault">
                Description
              </Label>
            </div> */}
          </div>
        </>
      )}
      <DeleteModal
        show={showDeleteModal}
        onDeleteClick={handleDeleteSubtitle}
        onCloseClick={() => {
          setShowDeleteModal(!showDeleteModal);
        }}
        deleteType={true}
      />
    </div>
  );
};

export default RightSection;
